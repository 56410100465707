<template>
  <b-row>
    <b-col lg="8">
      <b-card body-class="p-50" class="border mb-1">
        <app-detail-table :fields="fields" :data="visit">
          <template #physician="data">
            <div v-if="data.physician" class="d-flex flex-column">
              <span class="font-weight-bolder">{{ data.physician.auth.fullname }}</span>
              <small>{{ $physicianRole(data.physicianRole) }}</small>
            </div>
            <span v-else> - </span>
          </template>
          <template #status="item">
            <b-badge :variant="$variants[item.status.key]">
              {{ item.status.value }}
            </b-badge>
          </template>
        </app-detail-table>
        <div v-if="visit.status.key === 'planed'">
          <hr class="m-0" />
          <b-row class="mt-1 mb-50" style="gap: 5px 0">
            <b-col lg="3">
              <app-button text="Ziyareti Başlat" icon="BriefcaseIcon" block />
            </b-col>
            <b-col lg="3">
              <app-button text="Tarih Güncelle" icon="CalendarIcon" block @click="updateStartDate" />
            </b-col>
            <b-col lg="3">
              <app-button text="Yapıldı" icon="CheckIcon" block @click="setVisitCompleted" />
            </b-col>
            <b-col lg="3">
              <app-button text="Yapılamadı" icon="XIcon" block @click="updateToNotCompleted" />
            </b-col>
          </b-row>
        </div>
      </b-card>
    </b-col>
    <b-col v-if="visit.notCompletedReason" lg="4">
      <b-card class="border rounded mb-1">
        <b-card-sub-title class="d-flex gap-10 align-items-center">
          <span class="text-dark">Yapılamama Nedeni: </span>
          <b-badge>{{ visit.notCompletedReason.title }}</b-badge>
        </b-card-sub-title>
        <hr class="m-50" />
        {{ visit.notCompletedReason.description }}
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import UpdateVisitStartDate from "./UpdateVisitStartDate.vue";
import UpdateVisitToNotCompleted from "./UpdateVisitToNotCompleted.vue";

export default {
  data() {
    return {
      fields: [
        { key: "startDate", label: "Planlanan Tarih", formatDate: true, icon: "CalendarIcon" },
        { key: "company.name", label: "Firma", icon: "BriefcaseIcon" },
        { key: "branch.name", label: "Sicil", icon: "BriefcaseIcon" },
        { key: "physician", label: "Görevli", icon: "UserIcon" },
        { key: "status", label: "Durum", mdiIcon: "ListStatus" },
      ],
    };
  },
  computed: {
    visit() {
      return this.$store.getters.visit;
    },
  },
  methods: {
    setVisitCompleted() {
      this.$confirm({ message: "Ziyaret tamamlandı olarak işaretlenecektir. Onaylıyor musunuz?" }, () => {
        this.$store.dispatch("setVisitCompleted", this.visit._id);
      });
    },
    updateStartDate() {
      this.$showAppSidebar("Tarih Güncelle", UpdateVisitStartDate);
    },
    updateToNotCompleted() {
      this.$showAppSidebar("Yapılamadı Olarak Güncelle", UpdateVisitToNotCompleted);
    },
  },
};
</script>

<style></style>
