<template>
  <app-overlay loading-variable="sidebarOverlay">
    <validation-observer ref="updateVisitToNotCompletedForm">
      <app-select-input v-model="title" :options="options" name="Yapılamama Sebebi" label="Yapılamama Sebebi:" placeholder="Seçim Yapınız..." rules="required" />
      <app-textarea-input v-model="description" name="Açıklama" label="Açıklama:" placeholder="Açıklama..." rules="required|max:500" />
      <hr />
      <app-button text="Güncelle" block @click="updateVisitToNotCompleted" />
    </validation-observer>
  </app-overlay>
</template>

<script>
import { ValidationObserver } from "vee-validate";

export default {
  components: { ValidationObserver },
  data() {
    return {
      title: null,
      description: null,
      options: ["İşyeri Kapalı", "Yetkiliye Ulaşılamadı", "Diğer"],
    };
  },
  computed: {
    visit() {
      return this.$store.getters.visit;
    },
  },
  methods: {
    updateVisitToNotCompleted() {
      if (this.visit) {
        this.$validate(this.$refs.updateVisitToNotCompletedForm, () => {
          this.$store.dispatch("updateVisitToNotCompleted", { id: this.visit._id, data: { notCompletedReason: { title: this.title, description: this.description } } });
        });
      }
    },
  },
};
</script>

<style></style>
